<template>
	<div class="test2">
		<div class="mb-md">
			<!-- <input type="file" @change="chageFile" ref="inputFile" /> -->
			<slot name="button"></slot>
			<button class="blueBtn" style="padding: 5px 10px;" @click="exportExcelBtn">导出xlsx</button>
			<!-- <button v-if="bgbsm" class="blueBtn" @click="zxClicked">最新任务清单</button> -->
			<!-- <button v-if="!bgbsm" class="blueBtn" @click="editClicked">编辑</button> -->

			<el-button id="printPreviewBtn" style="display: none;" @click="printExcel()">打印预览</el-button>
			    <el-button id="printExcelBtn" style="display: none;" plain
			      v-print="{ id: 'print_html', popTitle: 'test111' }">打印</el-button>
			    <div id="print_html" class="procedure" style="text-align: center;z-index: 0;"></div>

			<button v-if="bgbsm==false" class="blueBtn" style="padding: 5px 10px;" @click="changeOrder">改单</button>
			<button v-if="bgbsm==false" class="blueBtn" style="padding: 5px 10px;" @click="save3">驳回</button>
			<button v-if="bgbsm==false && isEnd" class="blueBtn" style="padding: 5px 10px;" @click="save">审核通过</button>
			<button v-if="bgbsm==false && !isEnd" class="blueBtn" style="padding: 5px 10px;" @click="saveApproval">审核通过</button>
			<button v-if="bgbsm==true" class="blueBtn" style="padding: 5px 10px;" @click="save">{{saveText}}</button>
		</div>
		<!--web spreadsheet组件-->
		<div class="excel">
			<div id="luckysheetDom" style="margin: 0px; padding: 0px; width: 100%;"
				:style="'height:' + (windowHeight - 250) + 'px;'"></div>
		</div>
	</div>
</template>

<script>
	//引入依赖包
	import LuckyExcel from 'luckyexcel'
	const luckysheet = window.luckysheet
	//代码见下
	// import { exportExcel } from './export'
		import { exportSheetExcel } from '@/utils/export.js'
	export default {
		name: 'XspreadsheetDemo',
		props: {
			saveText:{
				type: String,
				default:"保存"
			},
			//是否只读
			allowEdit:{
				type:Boolean,
				default:true
			},
			bgbsm: {
				type: Boolean,
				default:true
			},
			isEnd: {
				type: Boolean,
				default:false
			},
			cellData:{
				type: Array
			},
			//列数
			columnNum:{
				type:Number,
			},
			//行数
			row:{
				type:Number,
			},
			//设置复选框区域
			dataVerification:{
				type:Object,
				default:()=>{}
			},
			//设置不可编辑区域
			authority:{
				type:Object,
				default:()=>{}
			},
			//是否冻结首行
			setHorizontalFrozen:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				xs: null,
				FormData: {},
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
			this.init()
		},
		methods: {
			init() {
				let that=this;
				// let options = localStorage.getItem('excel2')
				if (this.FormData = {}) {
					this.FormData = {
						container: 'luckysheetDom',
						title: '',
						lang: 'zh',
						allowEdit:this.allowEdit,
						row:this.row,
						column:this.columnNum,
						data: [{
							name: 'Sheet1',
							defaultColWidth:"110",
							celldata: this.cellData,
							config:{
								authority:this.authority
							},
							dataVerification: this.dataVerification,
						}],
						showinfobar: false,
						showtoolbarConfig:{
							undoRedo: false, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
							    paintFormat: false, //格式刷
							    currencyFormat: false, //货币格式
							    percentageFormat: false, //百分比格式
							    numberDecrease: false, // '减少小数位数'
							    numberIncrease: false, // '增加小数位数
							    moreFormats: false, // '更多格式'
							    font: false, // '字体'
							    fontSize: false, // '字号大小'
							    bold: false, // '粗体 (Ctrl+B)'
							    italic: false, // '斜体 (Ctrl+I)'
								strikethrough: false, // '删除线 (Alt+Shift+5)'
								underline: false, // '下划线 (Alt+Shift+6)'
							    textColor: false, // '文本颜色'
							    fillColor: false, // '单元格颜色'
							    border: false, // '边框'
							    mergeCell: false, // '合并单元格'
							    horizontalAlignMode: false, // '水平对齐方式'
							    verticalAlignMode: false, // '垂直对齐方式'
							    textWrapMode: false, // '换行方式'
							    textRotateMode: false, // '文本旋转方式'
								image:false, // '插入图片'
								link:false, // '插入链接'
							    chart: false, // '图表'（图标隐藏，但是如果配置了chart插件，右击仍然可以新建图表）
							    postil:  false, //'批注'
							    pivotTable: false,  //'数据透视表'
							    function: false, // '公式'
							    frozenMode: false, // '冻结方式'
							    sortAndFilter: false, // '排序和筛选'
							    conditionalFormat: false, // '条件格式'
								dataVerification: false, // '数据验证'
							    splitColumn: false, // '分列'
							    screenshot: false, // '截图'
							    findAndReplace: false, // '查找替换'
								protection:false, // '工作表保护'
								print:true, // '打印'
						},
						hook: {
						    cellMousedown: function (r, c) {
								// console.log("111")
								that.$emit("cellMousedown",r, c,luckysheet)
						    },
							cellUpdated:function(r,c,oldValue,newValue){
								// console.log(r)
								let data = luckysheet.toJson()
								that.$emit("cellUpdated",r,c,oldValue,newValue,data)
							},
							cellAllRenderBefore:function(data){
								// console.log(data)
								that.$emit("cellAllRenderBefore",data)
							}
						}
					}
					// console.log("FormData",this.FormData)
					luckysheet.create(this.FormData)
					if(this.setHorizontalFrozen==true){
						luckysheet.setHorizontalFrozen(false)
					}
				}
				// options.allowEdit = false
				if (this.bgbsm) {

				}
				// let url = GLOBAL_CONFIG.stxf + "/hzqd/getBybsm";
				//         this.http.longPost(url,{bsm:this.bgbsm}).then((res) => {
				//             if (res) {
				//                this.FormData = JSON.parse(res.data)
				//               //  console.log('this.FormData',res.data);
				//                console.log('this.FormData',this.FormData);
				//                this.FormData.allowEdit = false
				//                this.FormData.showinfobar = false
				//                this.FormData.showtoolbar = false
				//                this.FormData.lang = 'zh'
				//                luckysheet.create(this.FormData)
				//             }
				//         });
			},

			async printExcel() { // 打印预览事件实现， 参考文档: https://blog.csdn.net/alan_ji198573/article/details/128624832
			      this.printSheet()
			    },
			    printSheet() { // 打印操作：自动选中打印区域，并生成打印截图，进行打印
			      document.querySelector('#print_html').style = "display:block"
			      window.luckysheet.hideGridLines()

			      let currentSelected = luckysheet.getRange() // 获取当前选中区域

			      if (currentSelected[0] != null &&
			        (currentSelected[0].row[1] - currentSelected[0].row[0] >= 1 ||
			        currentSelected[0].column[1] - currentSelected[0].column[0] >= 1)) { // 如果当前选中区只是一个单元格，则认为选取无效。
			        luckysheet.getScreenshotNew((imgSrc) => { // 将打印区域生成base64图片（*将生成的base64编码复制粘贴到浏览器地址框，是可以预览图片样式的），生成后执行的后续打印操作，取用匿名委托函数做为参数传入
			          window.luckysheet.showGridLines()
			          let $img = `<img src=${imgSrc} style="max-width: 90%;" />`
			          this.$nextTick(() => {
			            document.querySelector('#print_html').innerHTML = $img
			          })
			          document.getElementById('printExcelBtn').click()
			          setTimeout(() => {
			            document.querySelector('#print_html').style = "display:none"
			          }, 100)
			        })
			      }
			      else {

			        const RowColumn = this.getPrintSheetArea() // 获取打印区域的行列
			        // 因需要打印左边的边框，需重新设置第一列
			        // RowColumn.column[0] = 0;

			        luckysheet.setRangeShow(RowColumn) // 进行选区操作
			        luckysheet.getScreenshotNew((imgSrc) => { // 将打印区域生成base64图片（*将生成的base64编码复制粘贴到浏览器地址框，是可以预览图片样式的），生成后执行的后续打印操作，取用匿名委托函数做为参数传入
			          window.luckysheet.showGridLines()
			          const $img = `<img src=${imgSrc} style="max-width: 90%;" />`
			          this.$nextTick(() => {
			            document.querySelector('#print_html').innerHTML = $img
			          })
			          document.getElementById('printExcelBtn').click()
			          setTimeout(() => {
			            document.querySelector('#print_html').style = "display:none"
			          }, 100)
			        })
			      }

			    },
			    getPrintSheetArea() { // 获取打印区域（即表格中有内容、非空白的区域）（用row，column数组表示）
			      const sheetData = luckysheet.getSheetData();
			      let objRowColumn = {
			        row: [0, 0], // 行
			        column: [0, 0], // 列
			      };

			      sheetData.forEach((item, index) => { // * item是行、index是行索引、it是一行里的一格、itemIndex是这一格在这一行里的列索引
			        item.forEach((it, itemIndex) => { // 行数
			          if (it !== null) {
			            if (objRowColumn.row[1] < index) {
			              objRowColumn.row[1] = index; // row第二位
			            }
			            if (objRowColumn.column[1] < itemIndex) {
			              objRowColumn.column[1] = itemIndex; // column第二位
			            }
			          }
			        });
			      });
			      return objRowColumn;
			    },

			save() {
				let data = luckysheet.toJson()
				// console.log(JSON.stringify(data))
				console.log(data)
				this.$emit("save",data,luckysheet.create(this.FormData))
				//        // localStorage.setItem('excel2', JSON.stringify(data))

				//        let url = GLOBAL_CONFIG.stxf + "/hzqd/save";
				//                this.http.post(url, {json:JSON.stringify(data)}).then((res) => {
				//                    if (res && res.data) {
				//                      this.$message.success('保存成功');
				//                      this.init()
				//                      this.$emit('getlsTable')
				//                    }
				//                });

			},
            saveApproval(){
                this.$emit("saveApproval")
            },
			editClicked() {
				this.FormData.showtoolbar = true
				this.FormData.allowEdit = true
				luckysheet.create(this.FormData)
			},
			refresh(e){
				this.FormData.data[0].celldata=e;
				console.log(this.FormData)
				this.$store.dispatch("setSellAskExcel", {
					sellAskExcel: this.FormData,
				});
				luckysheet.create(this.FormData)
				this.$router.go(0)
			},
			save2(){
				return luckysheet.toJson()
			},
			save3(){
				let data = luckysheet.toJson()
				// console.log(JSON.stringify(data))
				console.log(data)
				this.$emit("reject",data,luckysheet.create(this.FormData))
			},
			zxClicked() {
				this.bgbsm = ''
				this.init()
			},
			exportExcelBtn() {
				// console.log(luckysheet.getluckysheetfile())
				exportSheetExcel(luckysheet.getluckysheetfile(), '下载')
			},

			chageFile() {
				this.importExcel(this.$refs.inputFile.files[0])
				// let data = luckysheet.toJson()
				// console.log(JSON.stringify(data))

			},
			importExcel(file) {
				let name = file.name
				//获取文件后缀
				let suffixArr = name.split('.'),
					suffix = suffixArr[suffixArr.length - 1]
				if (suffix !== 'xlsx') {
					alert('目前只能导入xlsx类型的文件')
					return
				}
				LuckyExcel.transformExcelToLucky(file, this.fileCb, this.errorCb)
			},
			fileCb(exportJson, luckysheetfile) {
				// 转换后获取工作表数据
				if (exportJson.sheets === null || exportJson.sheets.length === 0) {
					alert('无法读取excel文件的内容，当前不支持xls文件!')
					return
				}
				// console.log('exportJson', exportJson)
				// console.log('luckysheetfile', luckysheetfile)

				luckysheet.destroy()

				luckysheet.create({
					container: 'luckysheetDom', //luckysheet is the container id
					showinfobar: false,
					data: exportJson.sheets,
					title: exportJson.info.name,
					userInfo: exportJson.info.name.creator
				})
			},
			errorCb(error) {
				console.log(error)
			},

			//-----------------改单-------------------
			changeOrder:function(){
				this.$emit("changeOrder")
			}
		}
	}
</script>
<style scoped lang="less">
	.test2 {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		.excel {
			flex: 1;
		}

		.mb-md {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 10px;

			button {
				margin-left: 20px;
			}
		}
	}
</style>
