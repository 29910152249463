<template>
	<div>
		<div class="table-page-search-wrapper" style="margin-bottom: 10px;padding: 20px;">
			<div v-if="userInfo">
				<el-row :gutter="21">
					<el-col :span="21">
						<div class="user">
							<div>客户编号：</div>
							<div>{{userInfo.id}}</div>
						</div>
					</el-col>
					<el-col :span="21">
						<div class="user">
							<div>客户积分：</div>
							<div>{{userInfo.userscore}}</div>
						</div>
					</el-col>
					<el-col :span="21">
						<div class="user">
							<div>联系手机：</div>
							<div>{{userInfo.contacttel}}</div>
						</div>
					</el-col>
					<el-col :span="7">
						<div class="user">
							<div style="width: 110px;">QQ号码：</div>
							<a-input placeholder="请输入QQ号码" v-model.trim="form.qq" />
						</div>
					</el-col>
					<el-col :span="7">
						<div class="user">
							<div style="width: 110px;">电子邮箱：</div>
							<a-input placeholder="请输入电子邮箱" v-model.trim="form.email" />
						</div>
					</el-col>
				</el-row>
			</div>
			<!-- <ko-excel></ko-excel> -->
			<!-- <button @click="jump">111111</button> -->
			<!-- <koButtonInput v-model="form2.name" :inputDisabled="false"></koButtonInput> -->
			<div class="bottom">
				<c-button type="warning" :height="33" style="margin-left: 10px;" @click="save">保存</c-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getUser,
		updateUser
	} from '@/api/userInfo/index'
	import Cookies from "js-cookie";
	import CButton from '@/components/CButton/index.vue'//button组件引入
	import koExcel from '@/components/excelJs/index.vue'
	// import koButtonInput from '@/components/koButtonInput/index.vue'
	export default {
		components: {
			CButton,
			koExcel
			// koButtonInput
		},
		data() {
			return {
				userInfo: null, //获取用户信息接口
				form2:{
					name:null
				},
				// 表单参数
				form: {
					id:undefined,//用户编号
					qq:undefined,//qq号码
					email:undefined,//邮箱
					uid:undefined,//用户编号
					pword:undefined,//新密码
					confirmpwd:undefined,//确认密码
					smscode:undefined,//验证码
					mobile:undefined,//手机号
				},
				myInput:null
			}
		},

		created() {
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.getUser()
		},

		mounted() {

		},

		watch: {
			// myInput(val){
			// 	console.log("111",val)
			// }
		},

		computed: {

		},

		methods: {
			//--------------获取用户信息数据----------------------
			getUser: function() {
				let data = {
					id: this.userInfo.id
				}
				getUser(data).then(res => {
					this.userInfo = res.data
					this.form.qq = res.data.qq
					this.form.email = res.data.email
					this.form.id = res.data.id
					this.form.uid = res.data.id
					sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo))
				})
			},
			
			jump:function(){
				this.form2.name="2222"
			},
			
			//---------------------修改个人信息---------------------------
			save(){
				this.$confirm('是否确认修改该信息？', '修改订单', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  let data={
					  id:this.form.id,
					  email:this.form.email
				  }
				  updateUser(this.form).then(res=>{
					this.$message.success('修改成功')
				  	this.getUser()
				  })
				}).catch(() => {});
			},
		}
	}
</script>

<style lang="less">
	/deep/ .ant-tabs-ink-bar {
		background-color: #f2ab15 !important;
	}
	
	/deep/ .ant-tabs-tab:hover {
		color: #f2ab15 !important;
	}
	
	/deep/ .ant-tabs-bar {
		margin: 0 !important;
	}
	
	/deep/ .ant-tabs-tab-active {
		color: #f2ab15 !important;
	}
	
	.user {
		display: flex;
		padding: 10px 0;
	}
	
	.bottom{
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
</style>